import styled from "styled-components";

const Container = styled.div`
`

const MainPhoto = styled.div`
  background-image: url('/szczesne6.jpg');
  background-size: cover;
  background-position: 20% 70%;
  height: 60vh;
  width: 100%;
  margin-top: 6rem;
  padding: 2rem;

  @media (max-width: 768px) {
        height: 30vh;
    }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  > *:not(:first-child) {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const Heading = styled.h2`
    text-align: center;
    margin-top: 2rem;
    font-size: 2.8rem;
    font-weight: 400;
    margin-bottom: 2rem;
    color: rgb(42,112,212);

    @media (max-width: 768px) {
       font-size: 2rem;
    }
`

const Text = styled.p`
    color: black;
    text-align: left;
    line-height: 30px;
    font-size: 1.1rem;

    @media (max-width: 768px) {
       font-size: .9rem;
       line-height: 26px;
    }
`

const SmallerHeading = styled.h2`
    text-align: left;
    font-size: 1.4rem;
    color: rgb(42,112,212);
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 768px) {
       font-size: 1.2rem;
    }
`

const TextContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 5%;
    gap: 2rem;
`

const SingleTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
`

const GrodziskMazowiecki = () => {
    return(
        <Container>
            <MainPhoto />

        <TextContent>
            <SingleTextDiv>
                <Heading>Grodzisk Mazowiecki</Heading>

                <Text>
                    Miasto w Polsce położone na Równinie Łowicko-Błońskiej, w województwie mazowieckim, w ppowiecie
                    grodziskim, nad rzeką Rokiciannką. Prawa miejsce uzyskała w 1522- 1870, a potem w 1916r.
                    Według danych GUS z 31 grudnia r miasto liczyło 34 448 mieszkańców.
                    Dzięki temu, że Grodzisk Mazowiecki jest jedną z najbogatszych gmin na Mazowszu oferowane są
                    rozrywki na bardzo wysokim poziomie- basen, kino, centrum handlowe, parki sportowo rekreacyjne, a
                    także restauracje i inne sklepy handlowo- usługowe to wszystko w zasięgu ręki.
                    Nie tylko rozrywka ale także nauka i kultura.
                    Nowo wybudowana szkoła podstawowa zlokalizowana we wsi Szczęsne. Na terenie gminy Grodziska
                    Mazowieckiego znajdują się liczne szkoły podstawowe, przedszkola, żłobki a także licea i technika.
                    Ośrodek Kultury ma szanse stać się przestrzenią wymiany wiedzy. Mediateka, biblioteka, organizowane
                    teatry dla dzieci, kabarety, wystawy fotograficzne, rzeźbiarskie a także malarskie pozwola na
                    zospokojenie potrzeb rozrywkowych.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>PARK SKARBKÓW</SmallerHeading>

                <Text>
                    Park znajduje się w centrum Grodziska Mazowieckiego, graniczy od północy z terenami PKP oraz Willą
                    Foksal w której mieści się Krajowy Ośrodek Badań i Dokumentacji Zabytków. Park został otwarty dla
                    mieszkańców około 1870 roku, wcześniej do połowy XIX wieku był częścią ogrodów jordanowickich
                    rodziny Mokronowskich. Początkowo park był bardziej rozległy i miały charakter parku zdrojowego.
                    Znajdował się tu Zakład Hydropatyczny dla Osób z Towarzystwa. Po wojnie światowej obszar parku
                    zmniejszył się, a zostało to spowodowane rozwojem miasta. W 2012 roku Park przeszedł rewitalizację
                    polegającą na wzbogaceniu układu kompozycyjno- przestrzennego parku. W ramach rewitalizacji
                    wybudowano nowy plac zabaw, fontannę, ścieżkę dydatktyczną. Park oficjalnie otwarto po rewitalizacji
                    22 lipca 2012 w 490 rocznicę otrzymania przez Grodzisk praw miejskich.
                    19 czerwca 2022 roku, w parku uruchomiono tężnię solankową. Tężnia ma wysokość ośmiu metrów oraz
                    kubaturę wynoszącą 920m3.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>STAWY WALCZEWSKIEGO</SmallerHeading>

                <Text>
                    Początkowo teren stawów należał do majątku Jordanowice hr Skarbków, który sprzedał gospodarstwo
                    rodzinie Walczewskich. Andrzej Walczewski (zm. 1948) w czasie II wojny światowej był członkiem AK,
                    przed wojną działaczem PPS po wojnie zaś prezesem grodziskiej Ochotniczej Straży Pożarnej. W 2010
                    roku przy Stawach Walczewskiego powstał kompleks boisk” Orlik 2012”
                    W 2017 roku teren przeszedł rewitalizację, która objęła min otwarcie kąpieliska, uporządkowanie terenu
                    parku, budowę pomostów oraz otwarcie wypożyczalni sprzętu wodnego. Obecnie Stawy Walczewskiego
                    są popularnym miejscem wśród mieszkańców Grodziska Mazowieckiego i okolic, są też miejscem , w
                    którym odbywa się dużo imprez miejskich oraz sportowych.
                    W 2018 roku Stawy Walczewskiego otrzymał wyróżnienie w XXIII edycji ogólnopolskiego konkursu
                    Modernizacja Roku, w kategorii „obiekty sportu i rekreacji”
                    W 2021 roku na terenie parku powstała rzeźba pt „Totem Zwierząt” autorska Andrzeja „Andre”
                    Zawadzkiego. Zabudowana jest z pnia topoli, która uległa zniszczeniu podczas burzy. Totem ma
                    wysokość 9,2 metra co czyni go najwyższą rzeźbą w Polsce.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>Stawy Goliana</SmallerHeading>

                <Text>
                    Teren rekreacyjno- sportowe położone w Grodzisku Mazowieckim przy ulicy Stawowej 10. Przyjmuje
                    się, że powstały w XIX wieku na skutek spiętrzenia rzeki Rokicianki. W roku 2012 w 490 rocznice
                    otrzymania praw miejskich przez Grodzisk Mazowiecki stawy poddano rewitalizacji, obejmującej
                    kompletną przebudowę obiektu, między innymu oczyszczanie i poszerzenie zbiorników wodnych,
                    nasadzenie roślin itd. W 2017 roku w ramach obchodów 100- lecia urodzin Leoniada Teligi na stawach
                    Goliana odsłonięto pomnik upamiętniający tego żeglarza.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>Mediateka</SmallerHeading>

                <Text>
                    Mediateka to innowacyjny projekt, który służy integracji społecznej wykorzystując przy tym cały
                    potencjał przestrzenny budynku jak i zaplecze merytoryczne, w osobach wykwalifikowanych specjalistów
                    z różnych dziedzin Wykorzystując nowe technologię i innowacyjne działania w szeroko rozumianej
                    kulturze, rozrywce, sztuce i literaturze. Mediateka jest na mapie Grodziska Mazowieckiego Przestrzenią
                    wymiany wiedzy, prezentacji pasji i twórczych rozwiązań.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>HALA WIDOWISKOWO- SPORTOWA</SmallerHeading>

                <Text>
                    Zespół dydaktyczno- sportowy o prostej, eleganckiej i zarazem nowoczesnej architekturze, optymalne
                    zaprojektowanych rozwiązań i połączeniach funkcjonalnych zapewniających właściwe użytkowanie.
                    Obiekt zlokalizowany jest w pobliżu istniejącej szkoły podstawowej, z której zapewniono połączenie na
                    poziomie pierwszego pietra przeszklonym łącznikiem, zachowując dość zwartą kompozycję kubaturową
                    oraz funkcjonalne rozmieszczone placów i innych urządzeń terenowych, zachowując maksymalnie zwarty
                    układ, uzupełniając go elementami małej architektury i nowo zaprojektowaną zielenią. Wielkość
                    podstawowej funkcji sportowej pozwala obsłużyć ok 200 osób trenujących, funkcji widowiskoweg ok 3
                    000 widzów oraz ok 100 osób w salach edukacyjno naukowych.
                </Text>
            </SingleTextDiv>

            <SingleTextDiv>
                <SmallerHeading>Planetarium</SmallerHeading>

                <Text>Planetarium i obserwatorium astronomiczne w Grodzisku Mazowieckim zaprasza od poniedziałku do
                czwartku do fascynujących zajęć z seansem w kinie sferycznym 3D do obserwowania gwiazd.</Text>
            </SingleTextDiv>

            </TextContent>
        </Container>
    )
}

export default GrodziskMazowiecki;