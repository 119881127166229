import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MainPhoto = styled.div`
  background-image: url('/szczesne6.jpg');
  background-size: cover;
  background-position: 20% 70%;
  height: 60vh;
  width: 100%;
  margin-top: 6rem;
  padding: 2rem;

  @media (max-width: 768px) {
        height: 30vh;
    }

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  > *:not(:first-child) {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const SecondHeading = styled.h2`
    text-align: center;
    margin-top: 6rem;
    font-size: 2.4rem;
    font-weight: 400;
`

const Text = styled.p`
    text-align: center;
    margin-top: 2rem;
`

const Map = styled.iframe`
    width: 100%;
    height: 400px;
    border: none;
    margin-top: 6rem;
`

const OpisFirmy = styled.div`
    background: rgb(42,112,212);
    background: linear-gradient(180deg, rgba(42,112,212,0.8869922969187675) 71%, rgba(110,166,247,0.7469362745098039) 100%);
    padding: 4rem 2rem;

    @media (max-width: 768px) {
        padding: 2rem;
    }
`

const OpisFirmySmaller = styled.div`
    background: rgb(42,112,212);
    background: linear-gradient(180deg, rgba(42,112,212,0.8869922969187675) 71%, rgba(110,166,247,0.7469362745098039) 100%);
    padding: 10rem 2rem;

    @media (max-width: 768px) {
        padding: 2rem;
    }
`

const OpisFirmyHeading = styled.h2`
    text-align: center;
    font-size: 2.4rem;
    color: white;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 768px) {
       font-size: 1.6rem;
    }
`

const NaszeInwestycje = styled.section`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    h2{
        text-align: center;
        font-size: 2.4rem;
        font-weight: 400;
    }
`

const InwestycjeContainer = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;
`

const Inwestycja = styled.div`
    background-color: #f2f2f2;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    border-radius: 1rem;
    min-height: 600px;
    width: 380px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        background: white;
    }

    img {
        width: 320px;
    }
`

const OpisFirmyTextFotka = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;

    img {
        border-radius: 12px;
        flex: 2;
        max-width: 1020px;

        @media (max-width: 989px) {
            width: 400px;
        }

        @media (max-width: 589px) {
            width: 98%;
        }
    }
`

const OpisFirmyText = styled.p`
    margin-top: 2rem;
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    flex: 2;
    line-height: 30px;
    font-size: 1.25rem;
    min-width: 520px;

    @media (max-width: 1399px) {
        font-size: .9rem;
        line-height: 22px;
    }

    @media (max-width: 589px) {
        min-width: 98%;
        margin-top: 0rem;
    }
`

const OpisFirmySmallerText = styled.p`
    width: 50%;
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    flex: 2;
    line-height: 30px;
    font-size: 1.25rem;
    margin-top: 2rem;

    @media (max-width: 1199px) {
        width: 90%;
    }

    @media (max-width: 786px) {
        width: 95%;
        font-size: 1rem;
        line-height: 24px;
    }
`

const Home = () => {
    const navigate = useNavigate();
  return (
    <>
    <MainPhoto />

    <OpisFirmySmaller>
        <OpisFirmyHeading>
            Deweloper z Grodziska Mazowieckiego
        </OpisFirmyHeading>

        <OpisFirmySmallerText>
            Firma KMK Development rozpoczęła swoją przygodę z budownictwem w 2020r. Jako nowy Deweloper
            na rynku, staramy się aby każda nasza inwestycja różniła się od innych proponowanych nieruchomości na
            terenie Grodziska Mazowieckiego i okolicach. Podczas realizacji naszych inwestycji zyskaliśmy reputację
            uczciwego oraz godnego zaufania Dewelopera na rynku.
        </OpisFirmySmallerText>
    </OpisFirmySmaller>

    <NaszeInwestycje>
        <h2>Nasze Inwestycje</h2>

        <InwestycjeContainer>
            <Inwestycja onClick={() => {navigate("/nad-stawami")}}>
                <div>
                <p style={{fontSize: "1.6rem", textAlign: "center"}}>Osiedle Nad Stawami</p>
                <p style={{fontSize: "1.3rem", textAlign: "center", color: "green"}}>(Zrealizowana)</p>
                </div>
                <img src="/homepage/home_page_plan.jpg"/>
                <p style={{fontSize: ".95rem"}}>Osiedle nad Stawami cechuje się unikalnym klimatem. Umiejscowione jest w sąsiedztwie lasu, łąk oraz stawów. Osiedle posiada sprzyjającą lokalizację.</p>
            </Inwestycja>
            <Inwestycja onClick={() => {navigate("/osiedle-dworska")}}>
                <div>
                <p style={{fontSize: "1.6rem", textAlign: "center"}}>Osiedle Dworska</p>
                <p style={{fontSize: "1.3rem", textAlign: "center", color: "green"}}>(Zrealizowana)</p>
                </div>
                <img src="/homepage/home_page_small.png"/>
                <p style={{fontSize: ".95rem"}}>Nasza inwestycja "Osiedle Dworska" położona jest w Osowcu gmina Żabia Wola w północno- zachodniej części gminy. Osowiec ciągnie się od wschodu do zachodu przeważnie po jednej stronie drogi, wysadzonej dużymi topolami, które nadają bardzo duży urok wsi.</p>
            </Inwestycja>
            <Inwestycja onClick={() => {navigate("/osiedle-polna")}}>
                <div>
                <p style={{fontSize: "1.6rem", textAlign: "center"}}>Osiedle Polna</p>
                <p style={{fontSize: "1.3rem", textAlign: "center"}}>(W Realizacji)</p>
                </div>
                <img src="/homepage/polna_plan_3.png"/>
                <p style={{fontSize: ".95rem"}}>Nasza inwestycja "Osiedle Polna" położona jest w Osowcu gmina Żabia Wola w północno - zachodniej części gminy. Osiedle to znajduje się na przeciwko naszej poprzedniej inwestycji - "Osiedle Dworska". Osowiec ciągnie się od wschodu do zachodu przeważnie po jednej stronie drogi, wysadzonej dużymi topolami, które nadają bardzo duży urok wsi.</p>
            </Inwestycja>
        </InwestycjeContainer>
    </NaszeInwestycje>

    <OpisFirmy style={{marginTop: "4rem"}}>
        <OpisFirmyHeading style={{marginBottom: "2rem"}}>
            O Firmie
        </OpisFirmyHeading>

        <OpisFirmyTextFotka>
            <OpisFirmyText>
                Prezes Spółki wychowany w Grodzisku Mazowieckim, znający każdy zakątek miasta a także znający
                potrzeby mieszkańców, rozpoczął swoją przygodę z przedsiębiorstwem zakładając Hurtownię
                elektryczną „Merkurion” w 1990 r, dzięki czemu pozyskał bardzo duże doświadczenie z nawiązywaniem
                współpracy w każdej dziedzinie, oraz dużą rzeszę odbiorców. Głody pozyskiwania doświadczenia, a także
                poszerzając swoją działalność w 2013 roku w Bieszczadach otworzył Hotel Premium p.n. „Hotel Carpatia
                Bieszczadzki Gościniec” w Smerku, który cieszy się popularnością, wśród elity turystycznej. Dzięki
                ciężkiej pracy Prezes jest uznawany za uczciwego oraz godnego zaufania przedsiębiorce, dla którego
                celem jest zadowolenie każdego klienta. Zbiegiem lat firma tak mocno rozwinęła się na rynku, że
                powstała nowa firma KMK DEVELOPMENT SP. Z O.O.
            </OpisFirmyText>

            <img src="/polna/trzy.jpeg"/>
        </OpisFirmyTextFotka>

        <OpisFirmyHeading style={{marginTop: "6rem", marginBottom: "2rem"}}>
            O Deweloperze
        </OpisFirmyHeading>

        <OpisFirmyTextFotka>
            <OpisFirmyText>
                            Firma KMK Development rozpoczęła swoją przygodę z budownictwem w 2020r. Jako nowy Deweloper
                na rynku, staramy się aby każda nasza inwestycja różniła się od innych proponowanych nieruchomości na
                terenie Grodziska Mazowieckiego i okolicach. Podczas realizacji naszych inwestycji zyskaliśmy reputację
                uczciwego oraz godnego zaufania Dewelopera na rynku. Specjalizujemy się w projektowaniu, budowie
                oraz sprzedaży domów wielorodzinnych z infrastrukturą pozwalającą niewątpliwie na bliskość z
                przyrodą a także z obecnością ciszy sprzyjającą atmosferze skupienia i relaksu, nabierania dystansu do
                codziennych obowiązków. Dzięki temu, że jesteśmy inwestorem oraz generalnym wykonawcą każdej
                naszej inwestycji pozwala nam to na indywidualne podejście do klienta proponując konkurencyjne ceny
                na rynku.
                KMK DEVELOPMENT SP. Z O.O. jest silnie dynamiczną oraz rozwijającą się firmą, której zadaniem
                jest budowanie z najwyższych jakościowo materiałów oraz spełnianie marzeń ludzi. Spółka współpracuje
                z wysoko wykwalifikowaną kadrą, dzięki której możemy pochwalić się ponadczasowymi oraz
                energooszczędnymi projektami.
                Każda nasza inwestycja budowana jest z pasją.
                Ciągła analiza rynku pozwala nam wychodzić naprzeciw oczekiwaniom klientów, nawet najbardziej
                wymagający klient będzie zadowolony z naszej współpracy. Spółka oferuje gotowe domy w stanie
                deweloperskim.
                Wyróżnia nas partnerskie a także indywidualne podejście do każdego naszego klienta. Wspieramy i
                pomagamy naszym klientom w pozyskiwaniu kredytu na zakup mieszkania.
            </OpisFirmyText>

            <img src="/dworska/dworska1.jpg"/>
        </OpisFirmyTextFotka>
    </OpisFirmy>

    <SecondHeading>
        Lokalizacja Firmy KMK Dewelopment
    </SecondHeading>

    <Text>
        Grodzisk Mazowiecki ul. Królewska 14
    </Text>

    <Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4900.213878652558!2d20.658607!3d52.114183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471948fa9eef24c1%3A0xbe37a2e38a00481d!2sKr%C3%B3lewska%2014%2C%2005-827%20Grodzisk%20Mazowiecki!5e0!3m2!1spl!2spl!4v1732365179137!5m2!1spl!2spl" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
    </>
  )
};

export default Home;