import styled from "styled-components"

const FooterContainer = styled.section`
    margin-top: 6rem;
    border-top: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: .5rem 6rem;

    
  @media (max-width: 768px) {
    padding: .5rem 2rem;
    justify-content: center;
  }

    img{
        width: 180px;
    }

    div{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
        min-width: 220px;

        @media (max-width: 768px) {
align-items: center;
  }
    }
`

const Footer = () => {
    return(
        <FooterContainer>
            <div>
                <p style={{fontWeight: 500, fontSize: "1.4rem"}}>KMK DEWELOPMENT</p>
                <p>05-825 Grodzisk Mazowiecki</p>
                <p>k.zukowski@merkurion.pl</p>
            </div>

            <div>
                <a href="tel:608072645" style={{fontWeight: "bold", color: "black", textDecoration: "none"}}>tel. 608-072-645</a>
                <a href="tel:664789127" style={{fontWeight: "bold", color: "black", textDecoration: "none"}}>tel. 664789127</a>
                <p>2024 KmkDewelopment</p>
            </div>

            <div>
                <img src="/logo_hipoteka.jpg"/>
            </div>

            <div>
                <p style={{fontWeight: 500, fontSize: "1.4rem"}}>Osiedla z dobrym klimatem</p>
                <p>Segmenty w pobliżu Grodziska Mazowieckiego</p>
                <p>KMK DEWELOPMENT</p>
            </div>
        </FooterContainer>
    )
}

export default Footer;